// .ant-list-item {
//   justify-content: start !important;
// }

// .checkbox {//   margin-right: 8px;
//   /* 调整选择框和表格之间的右侧间距 */
// }

// .ant-upload-wrapper {
//   display: inline-block !important;
//   margin-right: 20px;
//   margin-bottom: 20px;
// }

// .ant-pro-card-body{
//   padding-inline: 0px !important;
// }

// :where(.css-1qw3j7w).ant-pro-card .ant-pro-card-body{
//   padding-block: 0px !important;
// }

// .ant-table-container {
//   margin-top: auto !important; /* 向上移动的距离 */
// }

// .ant-table-tbody > tr > td {
//   padding: 0px!important;
//   text-align: left !important; /* 加上 !important 提高优先级 */
//   padding-left: 6px !important; /* 调整这里的数值以增加左侧空格的宽度 */
// }

// .ant-table-wrapper .ant-table.ant-table-middle{
//   font-size: 14px !important;
//   height: calc(100vh - 210px) !important;
//   background-color:  #F5F5F5;
// }

// .css-dev-only-do-not-override-1qw3j7w {
//   background-color:  transparent;
// }

// .ant-table-row.ant-table-row-level-0.compact-row {
//   height: 34px !important;
// }

// .ant-btn-sm {
//   line-height: 1.2714285714285714;
// }

// .ant-btn{
//   padding: 4px 5px !important;
// }

.custom-column {
  //padding-left: 7px !important; /* 调整这里的数值以增加左侧空格的宽度 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .custom-tooltip {           
//   white-space: pre-line;
// }

.custom-div {
  margin-top: 15px;
}

//搜索栏在div居中
:where(.css-dev-only-do-not-override-1xg9z9n).ant-form-inline {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: stretch;
  justify-content: flex-start;
  align-items: center;
}

.select-menu-div{
  background-color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px; /* 调整间距 */
  padding: 20px;
  margin-bottom: 30px; /* 设置下边距 */
}


.list-data-div{
  background-color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px; /* 调整间距 */
}

//Upload按钮问题设置高度使居中
.ant-form-item-control-input-content{   
  height: 32px;
}
