// .ant-list-item {
//   justify-content: start !important;
// }

// .checkbox {
//   margin-right: 16px;
//   /* 调整选择框和表格之间的右侧间距 */
// }

// .ant-table th,
// td {
//   border: 1px solid rgb(163, 163, 163);
// }

// .ant-table th {
//   background-color: rgb(187, 209, 209) !important;
// }

// .ant-checkbox-inner {
//   //修改边框的颜色
//   border: 1px solid #000 !important;
// }

.custom-table-wrapper .ant-table-cell {
  padding: 2px !important;
}

.custom-table-wrapper .ant-table-thead > tr > th {
  background-color: rgb(187, 209, 209); /* 设置表头背景颜色 */
}

.ant-list .ant-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.88);
}
