.login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: center/cover url('~@/assets/login.png');

  .login-header {
    // width: 200px;
    // height: 60px;
    display: flex;
    justify-content: center;
  }

  .login-logo {
    // width: 200px;
    // height: 60px;
    display: block;
    // margin: 0 auto;
    margin-right: 20px;
  }

  .login-title {

    text-align: center;
    font-size: large;
    line-height: 100px;
    font-weight: 800;
    color: #0db7d6;
  }

  .login-container {
    width: 440px;
    height: 360px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 50px rgb(0 0 0 / 10%);
  }

  .login-checkbox-label {
    color: #1890ff;
  }
}